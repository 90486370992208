<template>
  <div>
    <div class="admin-detail">
      <div class="admin-navbar justify-between">
        <button class="action-button" @click="$router.go(-1)">{{ $lang.app.back }}</button>
        <div class="detail-buttons">
          <button class="detail-button"
                  @click="$router.push(`/admin/applicants/${$route.params.id}/edit`)">
            {{ $lang.app.edit }}</button>
        </div>
      </div>
      <div v-if="applicant" class="admin-info">
        <h4 class="info-title">{{ $lang.app.user }}</h4>
        <div class="info-fields">
          <div class="info-field">
            <p class="field-label">{{ $lang.app.pin }}:</p>
            <p class="field-value">{{ applicant.pin }}</p>
          </div>
          <div class="info-field">
            <p class="field-label">{{ $lang.app.fullname }}:</p>
            <p class="field-value">{{ applicant.fio }}</p>
          </div>
          <div class="info-field">
            <p class="field-label">{{ $lang.app.birth_date }}:</p>
            <p class="field-value">{{ $moment(applicant.dateOfBirth).format('DD.MM.YYYY') }}</p>
          </div>
          <div class="info-field">
            <p class="field-label">{{ $lang.app.phone_number }}:</p>
            <p class="field-value">{{ applicant.phone }}</p>
          </div>
          <div class="info-field">
            <p class="field-label">{{ $lang.app.email }}:</p>
            <p class="field-value">{{ applicant.email }}</p>
          </div>
          <div class="info-field">
            <p class="field-label">{{ $lang.app.citizenship }}:</p>
            <p v-if="applicant.citizenship" class="field-value">
              <!-- {{ helper.citizenships.find(citizenship => citizenship.value === applicant.citizenshipValue).label }} -->
              {{ Number(applicant.citizenship) === 1 ? "KG" : "FOREIGN" }}
            </p>

          </div>
          <div v-if="applicant.citizenship === 'FOREIGN'">
            <div class="info-field">
              <p class="field-label">{{ $lang.app.country_code }}:</p>
              <p v-if="applicant.foreignCode" class="field-value">
                {{ helper.countryCodes.find(countryCode => countryCode.code === applicant.foreignCode).title }}
              </p>
            </div>
            <div class="info-field">
              <p class="field-label">{{ $lang.app.document_number }}:</p>
              <p class="field-value">{{ applicant.foreignDoc }}</p>
            </div>
          </div>
          <div v-else>
            <div class="info-field">
              <p class="field-label">{{ $lang.app.passport_seria }}:</p>
              <p class="field-value">{{ applicant.passportRange }}</p>
            </div>
            <div class="info-field">
              <p class="field-label">{{ $lang.app.passport_number }}:</p>
              <p class="field-value">{{ applicant.passportId }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      helper: {
        citizenships: [
          {
            label: this.$lang.app.citizen_kg,
            value: 'KG'
          },
          {
            label: this.$lang.app.citizen_other,
            value: 'FOREIGN'
          }
        ],
        countryCodes: [],
      },
      applicant: null,
    }
  },
  created() {
    this.setCountryCodes()
    this.fetchApplicant()
  },
  methods: {
    setCountryCodes() {
      this.$axios.get(`/country`).then(({data}) => {
        this.helper.countryCodes = data
      })
    },
    fetchApplicant() {
      this.$axios.get(`/applicant/${this.$route.params.id}`).then(({data}) => {
        this.applicant = data
      })
    },
  }
}
</script>
<style>
@import '../../../assets/styles/admin/detail.css';
@import '../../../assets/styles/admin/info.css';
@import '../../../assets/styles/admin/navbar.css';
</style>